export enum AdvancedTasksFilter {
  PAGE_INDEX = 'pageIndex',
  PAGE_SIZE = 'pageSize',
  CATEGORY = 'category',
  TASK_FILTER = 'taskFilter',
  SORT = 'sort',
  SEARCH_TOKEN = 'searchToken',
}
export enum AdvancedIpRightsFilter {
  INTERNAL_ID = '_f_internalId',
  SHORT_NAME = '_f_shortName',
  NAME = '_f_name',
  TYPE = '_f_type',
  COUNTRIES = '_incl_countries',
  STAGE = '_f_stage',
  PRIORITY_DATE_FROM = '_f_priorityDate_from',
  PRIORITY_DATE_TO = '_f_priorityDate_to',
  REQUEST_DATE_FROM = '_f_requestDate_from',
  REQUEST_DATE_TO = '_f_requestDate_to',
  RELEASE_DATE_FROM = '_f_releaseDate_from',
  RELEASE_DATE_TO = '_f_releaseDate_to',
  PROLONGATION_DATE_FROM = '_f_prolongationDate_from',
  PROLONGATION_DATE_TO = '_f_prolongationDate_to',
  EXPIRE_DATE_FROM = '_f_expiryDate_from',
  EXPIRE_DATE_TO = '_f_expiryDate_to',
  KEY_RIGHT_HOLDER = '_f_keyRightHolder.name',
  ACCESS_AGENT = '_f_accessAgent.name',
}
