import { IJwtDto, IUserSignInRes } from '@ipnote/interface';
import { Action, createReducer, on } from '@ngrx/store';
import {
  UserSetAiActions,
  UserSetBackUrl,
  UserSetCommand,
  UserSetCrowdfunding,
  UserSetData,
  UserSetKeyThrottler,
  UserSetNewRegistration,
  UserSetProviderIdFromPublicPage,
  UserSetRedirectFromOldAiChatPage,
  UserSignInSuccess,
  UserSignOut,
  UserSignUpSuccess,
} from '../../actions/user.actions';

export interface StateUser {
  isAuthenticated: boolean;
  token: IJwtDto | null;
  user: IUserSignInRes | null;
  keyThrottler: string | null;
  returnLink: string | null;
  aiActions: string | null;
  newRegistration: boolean;
  command: string | null;
  redirectFromOldAiChatPage: boolean;
  providerIdFromPublicPage: number | null;
}

export const initialStateUser: StateUser = {
  isAuthenticated: false,
  token: null,
  user: null,
  keyThrottler: null,
  returnLink: null,
  aiActions: null,
  newRegistration: false,
  command: null,
  redirectFromOldAiChatPage: false,
  providerIdFromPublicPage: null,
};

const userReducer = createReducer(
  initialStateUser,
  on(UserSetData, (state, { user }) => ({
    ...state,
    user,
  })),
  on(UserSetKeyThrottler, (state, { key }) => ({
    ...state,
    keyThrottler: key,
  })),
  on(UserSetBackUrl, (state, { url }) => ({
    ...state,
    returnLink: url,
  })),
  on(UserSetAiActions, (state, { aiActions }) => ({
    ...state,
    aiActions,
  })),
  on(UserSetCommand, (state, { command }) => ({
    ...state,
    command,
  })),
  on(UserSetRedirectFromOldAiChatPage, (state, { redirect }) => ({
    ...state,
    redirectFromOldAiChatPage: redirect,
  })),
  on(UserSignInSuccess, (state, data) => ({
    ...state,
    isAuthenticated: true,
    token: data.user.token,
    user: data.user.user,
    keyThrottler: null,
  })),
  on(UserSignUpSuccess, (state, { userResponse }) => ({
    ...state,
    isAuthenticated: true,
    token: userResponse.token,
    user: userResponse.user,
    keyThrottler: null,
  })),
  on(UserSignOut, () => initialStateUser),
  on(UserSetNewRegistration, (state, { newRegistration }) => ({
    ...state,
    newRegistration,
  })),
  on(UserSetCrowdfunding, (state, { showedCrowdfunding }) => ({
    ...state,
    user: {
      ...state.user,
      settings: {
        ...state.user.settings,
        showedCrowdfunding,
      },
    },
  })),
  on(UserSetProviderIdFromPublicPage, (state, { providerIdFromPublicPage }) => ({
    ...state,
    providerIdFromPublicPage,
  })),
);

export function userReducerDefault(state: StateUser | undefined, action: Action) {
  return userReducer(state, action);
}
