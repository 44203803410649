import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';
import { Observable } from 'rxjs';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import { map, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';
import { CompanyTypeEnum, Country, PublicPageSteps } from '@ipnote/enum';
import googleIcon from '@iconify/icons-logos/google-icon';
import linkedInIcon from '@iconify/icons-logos/linkedin-icon';
import { StateSignUp } from 'app/store/reducers/user';
import { selectSignUpState } from 'app/store/selectors/user.selectors';
import { CountriesSortService } from '@vex/services/countries-sort.service';
import { DialogService } from 'app/app-common/services/dialogs/dialog.service';
import { UserSignInGoogle, UserSignInLinkedIn, UserSignUp, UserSignUpReset } from 'app/store/actions/user.actions';
import { setFormError } from 'app/app-common/utils/set-form-error';
import { Router } from '@angular/router';
import { EnumsService } from '../../../../desk/services/enums/enums.service';
import { fadeInUp400ms } from '../../../../../../@vex/animations/fade-in-up.animation';

export interface IDialingCodes {
  dialingCode: string;
  country: string;
}
@UntilDestroy()
@Component({
  selector: 'app-personal-field',
  templateUrl: './personal-field.component.html',
  styleUrls: ['./personal-field.component.scss'],
  animations: [fadeInUp400ms],
})
export class PersonalFieldComponent implements OnInit {
  readonly googleIcon = googleIcon;
  readonly linkedInIcon = linkedInIcon;
  public publicPageSteps = PublicPageSteps;
  visible = false;
  public signUpStore$: Observable<StateSignUp> = this.store.select(selectSignUpState);
  icVisibility = icVisibility;
  icVisibilityOff = icVisibilityOff;

  inputType = 'password';

  filteredCountries: Observable<string[]>;
  countriesList = this.countriesSortService.sort(Object.values(Country));
  countrySearch = new UntypedFormControl();

  @Input() fromMarketPlace;
  @Input() sideNavMode: boolean;
  @Input() companyType: CompanyTypeEnum;
  @Output() companyTypeHandler = new EventEmitter<CompanyTypeEnum>();
  @Output() email = new EventEmitter<string>();
  @Output() stepsHandler = new EventEmitter<PublicPageSteps>();

  @Input() set tempUserUuid(tempUserUuid: any) {
    this.form.get('tempUserUuid').setValue(tempUserUuid);
  }

  constructor(
    private store: Store<AppState>,
    private countriesSortService: CountriesSortService,
    private translocoService: TranslocoService,
    private dialogs: DialogService,
    private router: Router,
    public enums: EnumsService,
  ) {}

  form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    secondName: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [
      Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'),
      Validators.required,
    ]),
    phone: new FormControl(),
    password: new UntypedFormControl('', Validators.minLength(8)),
    fromMarketplace: new UntypedFormControl(),
    company: new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      country: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl(),
      //isServiceProvider: new UntypedFormControl(),
      baseCounterparty: new UntypedFormGroup({
        name: new UntypedFormControl(null),
      }),
    }),
  });

  send(): void {
    this.markInvalidFields();

    if (this.form.invalid) {
      return;
    }

    this.form.controls.fromMarketplace.setValue(!!this.fromMarketPlace);
    this.form.get('company').get('baseCounterparty').get('name').setValue(this.form.get('company').get('name').value);
    this.form.get('company').get('type').setValue(this.companyType);
    const { dialCode, countryCode, e164Number } = this.form.controls.phone.value;
    const phone = { number: e164Number.replace(dialCode, ''), dialCode: dialCode, countryCode: countryCode };
    const user = {
      ...this.form.value,
      phone: phone,
    };
    this.store.dispatch(UserSignUp({ user }));
  }

  markInvalidFields() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
  }

  ngOnInit(): void {
    this.filteredCountries = this.countrySearch.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterCountries(value)),
      untilDestroyed(this),
    );
    this.signUpStore$.subscribe((state) => {
      if (state.error !== null) {
        if (typeof state.error.error.message !== 'string') {
          const formatError = setFormError(this.form, state.error.error.message);
          this.form = formatError.form;
        } else {
          this.dialogs.error(state.error);
        }
      }
      if (state.success) {
        this.store.dispatch(UserSignUpReset());
        this.email.emit(this.form.get('email').value);
      }
    });
  }

  toggleVisibility(): void {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
    } else {
      this.inputType = 'text';
      this.visible = true;
    }
  }

  get joinButtonText(): string {
    let buttonText = 'Create an account';
    if (this.companyType == 'customer') buttonText = 'join as a customer';
    else if (this.companyType == 'provider') buttonText = 'join as a service provider';
    return buttonText;
  }

  changeCompanyType() {
    if (this.companyType == CompanyTypeEnum.CUSTOMER) {
      this.companyType = CompanyTypeEnum.PROVIDER;
      this.companyTypeHandler.emit(CompanyTypeEnum.PROVIDER);
    } else {
      this.companyType = CompanyTypeEnum.CUSTOMER;
      this.companyTypeHandler.emit(CompanyTypeEnum.CUSTOMER);
    }
  }

  private filterCountries(value: string): string[] {
    if (value == null) return this.countriesList;
    const startsWithCountries = this.countriesList.filter((country) =>
      this.translocoService.translate(country).toLowerCase().startsWith(value.toLowerCase()),
    );
    const remainingCountries = this.countriesList.filter(
      (country) => !this.translocoService.translate(country).toLowerCase().startsWith(value.toLowerCase()),
    );
    const includesCountries = remainingCountries.filter((country) =>
      this.translocoService.translate(country).toLowerCase().includes(value.toLowerCase()),
    );

    return [...startsWithCountries, ...includesCountries];
  }

  signInLinkedIn() {
    this.store.dispatch(UserSignInLinkedIn());
  }

  signInGoogle() {
    this.store.dispatch(UserSignInGoogle());
  }
}
