import { ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AiWidgetChatState } from '../reducers/ai-widget/ai-widget-chat.reducer';
import { IAiChatStoredState } from '@ipnote/interface';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      'user',
      'companyList',
      'companySelect',
      'router',
      'trademarkSearch',
      'chats',
      'tasks',
      'oips',
      {
        aiChat: {
          serialize: (state: AiWidgetChatState) => ({ returnButtons: state.returnButtons }),
          deserialize: (storedState: IAiChatStoredState) => ({ returnButtons: storedState?.returnButtons ?? null }),
        },
      },
    ],
    rehydrate: true,
  })(reducer);
}
