<div @fadeInUp class="sign-up">
  <ipnote-first-page-header></ipnote-first-page-header>
  <div *ngIf="step === enums.publicPageSteps.CHOICE_COMPANY_TYPE" class="choice-company-type-form">
    <mat-radio-group color="primary" class="flex space-x-3" [(ngModel)]="companyType">
      <div
        class="sign-up__radio-btn"
        [ngClass]="{'blue-border': companyType === enums.companyTypeEnum.CUSTOMER}"
        (click)="choiceType(enums.companyTypeEnum.CUSTOMER)"
      >
        <mat-radio-button class="absolute top-3 right-1" [value]="enums.companyTypeEnum.CUSTOMER"></mat-radio-button>
        <span class="sign-up__radio-text text-center">
          <span class="text-primary-500">I`m a client</span> needing IP <br> assistance
        </span>
      </div>
      <div
        class="sign-up__radio-btn"
        [ngClass]="{'blue-border': companyType === enums.companyTypeEnum.PROVIDER}"
        (click)="choiceType(enums.companyTypeEnum.PROVIDER)"
      >
        <mat-radio-button class="absolute top-3 right-1" [value]="enums.companyTypeEnum.PROVIDER"></mat-radio-button>
        <span class="sign-up__radio-text text-center">
          <span class="text-primary-500">I`m an IP provider</span> offering IP <br> services
        </span>
      </div>
    </mat-radio-group>

    <button
      class="sign-up__button w-full h-[40px]"
      [ngClass]="{'white-text': companyType}"
      id="select_is_provider_signup"
      mat-raised-button
      [disabled]="!companyType"
      (click)="step=enums.publicPageSteps.PERSONAL_FIELD"
    >
      {{ joinButtonText }}
    </button>
    <div
      class="confirm-conditions mt-4"
      [innerHTML]="'confirm_conditions' | transloco | safe:'html'"
    ></div>

<!--    <app-idp-sign-in></app-idp-sign-in>-->

    <div class="confirm-conditions mt-4">
      <span class="pr-1">{{ "already-have-an-account" | transloco }}</span>
      <a [routerLink]="['/auth/log-in']">{{ "sign-in-here" | transloco }}</a>
    </div>
  </div>
  <app-personal-field
    *ngIf="step===enums.publicPageSteps.PERSONAL_FIELD"
    [companyType]="companyType"
    (companyTypeHandler)="changeCompanyType($event)"
    (email)="email=$event"
  >
  </app-personal-field>
</div>
