import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CompaniesService } from '../../../services/companies/companies.service';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { FileManagerService } from '../../../../../app-common/services/file-manager/file-manager.service';
import icLogo from '@iconify/icons-ic/baseline-apartment';
import { DialogService } from '../../../../../app-common/services/dialogs/dialog.service';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorService } from '../../../../../app-common/services/error-service/error-service';
import { CountriesSortService } from '../../../../../../@vex/services/countries-sort.service';
import { RelationsService } from '../../../../../app-common/services/links/relations.service';
import { ServicesService } from '../../../services/services/services.service';
import { ICompanyEntity, ICreateCompanyDto } from '@ipnote/interface';
import { setFormError } from '../../../../../app-common/utils/set-form-error';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../app.states';
import { CompanyCreate } from '../../../../../store/actions/company.actions';
import { selectStateCompanyCreate, selectStateSelectedCompany } from 'app/store/selectors/company.selectors';
import { StateCompanyCreate } from '../../../../../store/reducers/company';
import { EnumsService } from '../../../services/enums/enums.service';
import { PermissionService } from '../../../../../app-common/guards/permission.service';
import { CompanyTypeEnum, Country, Currency } from '@ipnote/enum';
import { DEFAULT_CURRENCY } from '@ipnote/const';

@UntilDestroy()
@Component({
  selector: 'app-company-creator',
  templateUrl: './company-creator.component.html',
  styleUrls: ['./company-creator.component.scss'],
})
export class CompanyCreatorComponent implements OnInit {
  isLoading;
  @Output() companyChange = new EventEmitter<true>();
  public selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  public selectedCompany: ICompanyEntity;
  public companyCreateStore$: Observable<StateCompanyCreate> = this.store.select(selectStateCompanyCreate);
  urlExp =
    'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)';
  @Input() companyType: string;
  companyTypes: CompanyTypeEnum[] = [];
  countriesList = this.countriesSortService.sort(Object.values(Country));
  currencies = Object.keys(Currency);
  formCompany = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    country: new UntypedFormControl('', this.countryValidator(this.countriesList)),
    defaultCurrency: new UntypedFormControl(DEFAULT_CURRENCY, Validators.required),
    logo: new UntypedFormControl(''),
    baseCounterparty: new UntypedFormGroup({
      email: new UntypedFormControl(null, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')),
      // type: new UntypedFormControl(''),
      address: new UntypedFormControl(null),
    }),
    //isActive: new UntypedFormControl(false),
    type: new UntypedFormControl({ value: null, disabled: true }, [Validators.required]),
    //isServiceProvider: new UntypedFormControl(false)
  });

  countrySearch = new UntypedFormControl();

  error: Error;
  formSaveInProcess = false;
  userId: number | null;
  userRoles: string[] | null;
  icLogo: any = icLogo;
  filteredCountries: Observable<string[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private companiesService: CompaniesService,
    private uploadService: FileManagerService,
    private dialogs: DialogService,
    private cd: ChangeDetectorRef,
    private translocoService: TranslocoService,
    public errorService: ErrorService,
    private countriesSortService: CountriesSortService,
    private relationsService: RelationsService,
    private servicesService: ServicesService,
    private store: Store<AppState>,
    public enums: EnumsService,
    public permissionService: PermissionService,
  ) {
    this.selectedCompany$.pipe(untilDestroyed(this)).subscribe((company) => {
      this.selectedCompany = company;
    });
    this.companyCreateStore$.pipe(filter((state, i) => state.error !== null)).subscribe((state) => {
      if (typeof state.error.error.message !== 'string') {
        const formatError = setFormError(this.formCompany, state.error.error.message);
        this.formCompany = formatError.form;
      } else {
        this.dialogs.error(state.error);
      }
    });
    this.permissionService
      .checkRole(['can-everything'])
      .pipe(
        filter((p) => !!p),
        untilDestroyed(this),
      )
      .subscribe((result) => {
        this.formCompany.get('type').enable();
      });
  }

  async ngOnInit(): Promise<void> {
    this.setCompanyType();

    this.filteredCountries = this.countrySearch.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterCountries(value)),
      untilDestroyed(this),
    );
  }

  private setCompanyType() {
    if (this.companyType) {
      this.companyTypes = Object.values(CompanyTypeEnum);
      this.formCompany.get('type').setValue(this.companyType);
    } else {
      // if company create from Google or linkedId
      this.companyTypes = Object.values(CompanyTypeEnum).filter((type) => type != CompanyTypeEnum.PROVIDER_CLIENT);
      this.formCompany.get('type').enable();
    }
  }

  countryValidator(countries: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && countries.includes(control.value)) {
        return null;
      }
      return { country: true };
    };
  }

  save(): void {
    this.isLoading = true;
    const formCompanyData: ICreateCompanyDto = this.formCompany.getRawValue();
    formCompanyData.baseCounterparty.name = formCompanyData.name;
    const { logo } = formCompanyData;
    // @ts-ignore
    const upload: Observable<string> = logo instanceof File ? this.uploadService.upload(logo) : of(logo);

    upload
      .pipe(
        map((uploadLogo) => Object.assign({}, formCompanyData, { logo: uploadLogo })),
        tap((company) => {
          if (this.companyType !== this.enums.companyTypeEnum.PROVIDER_CLIENT) {
            this.store.dispatch(CompanyCreate({ company }));
          }
        }),
        untilDestroyed(this),
      )
      .subscribe((res) => {
        if (this.companyType === this.enums.companyTypeEnum.PROVIDER_CLIENT) {
          this.companiesService.create(res).subscribe(
            (result) => {
              this.companyChange.emit(true);
              this.isLoading = false;
            },
            (error) => this.dialogs.error(error),
          );
        } else {
          this.companyChange.emit(true);
        }
      });
  }

  countryDisplayWith(country: string): string {
    if (!country || !this.countriesList) return '';
    return this.translocoService.translate(this.countriesList.find((item) => item === country));
  }

  async getServices(company: ICompanyEntity): Promise<any> {
    return this.servicesService
      .getAll(0, 9999, null, null, { isActive: true }, null, null, { country: company.country })
      .pipe(map((p) => p.data.map((item) => item)))
      .toPromise();
  }

  private filterCountries(value: string): string[] {
    if (value == null) return this.countriesList;

    const startsWithCountries = this.countriesList.filter((country) =>
      this.translocoService.translate(country).toLowerCase().startsWith(value.toLowerCase()),
    );
    const remainingCountries = this.countriesList.filter(
      (country) => !this.translocoService.translate(country).toLowerCase().startsWith(value.toLowerCase()),
    );
    const includesCountries = remainingCountries.filter((country) =>
      this.translocoService.translate(country).toLowerCase().includes(value.toLowerCase()),
    );

    return [...startsWithCountries, ...includesCountries];
  }
}
