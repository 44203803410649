<div class="first-page">
  <div class="first-page-img">
    <img class="w-[150px] h-[42px]" src="assets/img/new-ipnote-logo.png" alt=""/>
  </div>
<!--  <div class="first-page-header"> AI powered IP revolution - <br> IP made-->
<!--    <div class="first-page-header__highlighted"> 5x cheaper </div> and-->
<!--    <div class="first-page-header__highlighted"> easier </div>-->
<!--  </div>-->
  <div class="first-page-header"> Join as a client or as a provider </div>
</div>
